.services {
  background: #181818;
  width: 100%;

  .services-hero {
    padding: 14.23vw 0 8.68vw;
    display: flex;
    justify-content: center;

    h2 {
      font-size: 9.72vw;
      font-weight: 400;
      font-family: 'Ganora';
    }
  }

  .services-content {
    display: flex;
    flex-direction: column;
    gap: 11.11vw;
    padding-bottom: 8vw;
  }

}

@media (max-width: 768px) {
    .services {
        .services-hero {
            padding: 21.34vw 0 13.02vw;

                h2 {
                    font-size: 14.58vw;
                }
            }

            .services-content {
            gap: 16.67vw;
            padding-bottom: 12vw;
        }
    }
}

  @media (max-width: 430px) {
    .services {
        .services-hero {
            padding: 42.69vw 0 26.04vw;

                h2 {
                    font-size: 18.16vw;
                    text-align: center;
                }
            }

            .services-content {
            gap: 33.33vw;
            padding-bottom: 24vw;
        }
    }
}