.contact-us-page {
    padding: 15.83vw 0 10vw;
    background: #181818;

    .contact-us-wrapper {
        display: flex;
        gap: 4.18vw;

        .content-us-content {
            display: flex;
            flex-direction: column;
            gap: 3.33vw;

            .content-us-content-header {
                display: flex;
                flex-direction: column;

                .title {
                    text-align: left;
                }

                .subtitle {
                    text-align: left;
                    text-transform: none;
                }
            }

            .content-us-content-inputs {
                display: flex;
                flex-direction: column;
                gap: 1.7vw;
            }
        }

        svg {
            min-width: 25vw;
            max-width: 25vw;
        }
    }
}

/* Media query for tablets (768px) */
@media (max-width: 768px) {
    .contact-us-page {
        padding: 23.745vw 0 15vw;

        .contact-us-wrapper {
            gap: 6.27vw;

            .content-us-content {
                gap: 4.995vw;

                .content-us-content-inputs {
                    gap: 2.55vw;
                }
            }

            svg {
                min-width: 25.5vw;
                max-width: 25.5vw;
            }
        }
    }
}

/* Media query for mobiles (430px) */
@media (max-width: 430px) {
    .contact-us-page {
        padding: 47.49vw 0 30vw;

        .contact-us-wrapper {
            flex-direction: column;
            gap: 12.54vw;

            .content-us-content {
                gap: 9.99vw;

                .content-us-content-inputs {
                    gap: 5.1vw;
                }
            }

            svg {
                display: none;
            }
        }
    }
}
