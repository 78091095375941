.burger{
    display: flex;
    flex-direction: column;
    position: relative;

    .burger-header{
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
        cursor: pointer;

        &.active{
            .burger-header-item:first-child{
                transform: rotate(45deg);
                top: 0.75vw;
            }

            .burger-header-item:nth-child(2){
                opacity: 0;
            }

            .burger-header-item:last-child{
                transform: rotate(-45deg);
                top: -0.75vw;
            }
        }

        .burger-header-item{
            position: relative;
            height: 0.25vw;
            width: 3.5vw;
            background: #fff;
            transition: all ease 0.4s;

            
        }
    }

    .burger-content{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2vw;
        position: fixed;
        left: 0;
        background: #181818;
        overflow: hidden;
        transition: all ease 0.4s;
        height: 0;
        max-height: 0;
        width: 100vw;

        a {
            text-transform: uppercase;
            color: #fff;
            text-decoration: none;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            gap: 0.6vw;
            font-size: 2.45vw;
        
            &::after {
                content: "";
                height: 0.25vw;
                max-width: 0;
                background: #fff;
                transition: all ease 0.4s;
            }
        
            &:hover {
                &::after {
                    max-width: 100%;
                }
            }
        }

        &.active{
            height: 100%;
            max-height: 100vh;
        }
    }
}


@media (max-width: 430px) {
    .burger {
        .burger-header{
            gap: 1vw;
    
            &.active{
                .burger-header-item:first-child{
                    top: 1.5vw;
                }
    
                .burger-header-item:last-child{
                    top: -1.5vw;
                }
            }
    
            .burger-header-item{
                position: relative;
                height: 0.5vw;
                width: 7vw;
    
                
            }
        }

        .burger-content {
            gap: 4vw;

            a {
                gap: 1.2vw;
                font-size: 4.9vw;

                &::after {
                    height: 0.5vw;
                }
            }
        }
    }
}
