.button{
    display: flex;
    flex-direction: column;
    gap: 0.3vw;
    border: 1px solid #fff;
    cursor: pointer;
    transition: background ease 0.4s;
    background: transparent;


    .button-text{
        color: #fff;
        font-weight: 700;
        text-align: center;
        padding: 0 2vw;
        line-height: 140%;
        letter-spacing: 0.2vw;
        font-size: 1.11vw;
    }

    .button-boarder{
        transition: all ease 0.4s;
        background: url('../../../assets/general/button.png') repeat;
        max-width: 0;
        height: 0.55vw;
    }

    &:hover{
        background: #9C7339;
        border: none;
        
        .button-boarder{
            max-width: 100%;
        }
    }
}

@media (max-width: 768px) {

    .button{
        gap: 0.45vw;
    
        .button-text{
            padding: 0 3vw;
            line-height: 140%;
            letter-spacing: 0.3vw;
            font-size: 1.6vw;
        }
    
        .button-boarder{
            height: 0.75vw;
        }
    }
    

}

@media (max-width: 430px) {
    .button {
        gap: 0.9vw;

        .button-text {
            padding: 0 6vw;
            letter-spacing: 0.6vw;
            font-size: 3.33vw;
        }

        .button-boarder {
            height: 1.65vw;
        }
    }
}