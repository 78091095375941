.service-item {
    display: flex;
    flex-direction: column;
    gap: 6.95vw;
    width: 100%;
  
    .service-item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .subtitle {
        width: 27.7vw;
        text-align: left;
        text-transform: none;
        letter-spacing: 0;
        line-height: 140%;
      }
  
      .title {
        text-align: left;
      }
    }
  
    .service-item-content {
      display: flex;
      justify-content: space-between;
      gap: 12.85vw;
      width: 100%;
  
      .service-item-content-items-container {
        display: grid;
        grid-gap: 4.3vw;
        grid-template-columns: 1fr 1fr;
  
        .service-item-content-item {
          display: flex;
          flex-direction: column;
          gap: 2.4vw;
          padding-bottom: 3.125vw;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  
          .service-item-content-item-title {
            font-size: 2.7vw;
            font-family: 'Ganora';
            font-weight: 400;
          }
  
          .service-item-content-item-content {
            display: flex;
            flex-direction: column;
            gap: 1.5vw;
  
            p {
              font-size: 1.11vw;
              line-height: 125%;
              display: flex;
              align-items: center;
              gap: 1vw;
            }
  
            p::before {
              content: "";
              min-width: 0.35vw;
              min-height: 0.35vw;
              border-radius: 50%;
              background: #9C7339;
            }
          }
        }
    }
  
        svg {
            min-height: 27.8vw;
            max-height: 27.8vw;
            min-width: 12.5vw;
        }
    }
}
  
  /* Media Queries */
@media (max-width: 768px) {
    .service-item {
      gap: 10.43vw;
    
        .service-item-header{
            .subtitle {
                width: 41.55vw;
            }
        }
        
        .service-item-content {

            .service-item-content-items-container {
                grid-gap: 6.45vw;

                .service-item-content-item {
                    gap: 3.6vw;
                    padding-bottom: 4.69vw;
                    .service-item-content-item-title {
                        font-size: 4.05vw;
                    }


                    .service-item-content-item-content {
                        gap: 2.25vw;

                        p {
                            font-size: 1.67vw;
                            gap: 1.5vw;
                        }

                        p::before {
                            min-width: 0.525vw;
                            min-height: 0.525vw;
                        }
                    }
                }
            }

            svg {
                min-height: 41.7vw;
                max-height: 41.7vw;
                min-width: 18.75vw;
            }
        }

    }
}
  
@media (max-width: 430px) {
    .service-item {
        gap: 20.85vw;


        .service-item-header{
            flex-direction: column;
            .subtitle {
                width: 83.1vw;
            }

            .title{
                text-align: center;
            }
        }

        .service-item-content {
            flex-direction: column;

            .service-item-content-items-container {
                grid-gap: 12.9vw;
                grid-template-columns: 1fr;


                .service-item-content-item {
                    gap: 7.2vw;
                    padding-bottom: 9.375vw;


                    .service-item-content-item-title {
                        text-align: center;
                        font-size: 8.1vw;
                    }
            
                    .service-item-content-item-content {
                        gap: 4.5vw;
                        align-items: center;
                        p {
                            font-size: 3.33vw;
                            gap: 3vw;
                        }

                        p::before {
                            min-width: 1.05vw;
                            min-height: 1.05vw;
                        }
                
                    }

                }
            }


            svg {
                min-height: 83.4vw;
                max-height: 83.4vw;
                min-width: 37.5vw;
            }
        }
    }
}

