.feedback {
    display: flex;
    flex-direction: column;
    gap: 4.86vw;

    .feedback-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .feedback-title-container {
            display: flex;
            flex-direction: column;
            align-items: left;

            .title, .subtitle {
                text-align: left;
            }
        }

        .feedback-header-buttons {
            display: flex;
            align-items: center;
            gap: 1vw;

            .feedback-header-button {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 3.125vw;
                height: 3.125vw;
                background: #333;
                cursor: pointer;
                transition: all ease 0.4s;

                &:hover {
                    background: #454545;

                    path {
                        stroke: #9C7339;
                    }
                }

                svg {
                    width: 0.7vw;
                }

                &:last-child {
                    svg {
                        transform: rotate(180deg);
                    }
                }

                &.disabled {
                    background: transparent;
                    cursor: auto;

                    &:hover {
                        background: transparent;

                        path {
                            stroke: #fff;
                        }
                    }
                }
            }
        }
    }

    .feedback-list-container {
        display: flex;
        gap: 2vw;
        overflow: hidden;

        .feedback-list-item {
            min-width: calc(50% - 1vw);
            max-width: calc(50% - 1vw);
            transition: all ease 0.4s;

            .feedback-list-item-header {
                display: flex;
                flex-direction: column;
                gap: 2vw;

                .feedback-list-item-header-content {
                    display: flex;
                    gap: 2vw;
                    align-items: center;

                    p {
                        color: #fff;
                        font-style: italic;
                        font-size: 1.38vw;
                        line-height: 160%;
                    }

                    &::before {
                        content: "";
                        width: 0.4vw;
                        background: #9C7339;
                        height: 5vw;
                        display: block;
                    }
                }
            }
        }

        .feedback-list-item-footer {
            display: flex;
            align-items: center;
            width: 80%;
            justify-content: space-between;
            margin-top: 3.5vw;

            .feedback-list-item-footer-content {
                display: flex;
                align-items: center;
                gap: 1.7vw;

                img {
                    width: 4.3vw;
                    height: 4.3vw;
                }

                .feedback-list-item-footer-name {
                    display: flex;
                    flex-direction: column;

                    h2 {
                        font-weight: bold;
                        color: #fff;
                        line-height: 160%;
                        font-size: 1.3vw;
                        text-transform: capitalize;
                    }

                    p {
                        text-transform: uppercase;
                        color: #9C7339;
                        font-weight: medium;
                        font-size: 0.9vw;
                        line-height: 160%;
                    }
                }
            }

            .feedback-list-item-footer-quote {
                width: 2.8vw;
            }
        }
    }
}

@media (max-width: 768px) {
    .feedback {
        gap: 7.29vw;

        .feedback-header-container {
            .feedback-header-buttons {
                gap: 1.5vw;

                .feedback-header-button {
                    width: 4.6875vw;
                    height: 4.6875vw;

                    svg {
                        width: 1.05vw;
                    }
                }
            }
        }

        .feedback-list-container {
            gap: 0;

            .feedback-list-item {
                min-width: 100%;
                max-width: 100%;

                .feedback-list-item-header {
                    gap: 3vw;

                    .feedback-list-item-header-content {
                        gap: 3vw;

                        p {
                            font-size: 2.07vw;
                        }

                        &::before {
                            width: 0.6vw;
                            height: 7.5vw;
                        }
                    }
                }
            }

            .feedback-list-item-footer {
                margin-top: 5.25vw;

                .feedback-list-item-footer-content {
                    gap: 2.55vw;

                    img {
                        width: 6.45vw;
                        height: 6.45vw;
                    }

                    .feedback-list-item-footer-name {
                        h2 {
                            font-size: 1.95vw;
                        }

                        p {
                            font-size: 1.35vw;
                        }
                    }
                }

                .feedback-list-item-footer-quote {
                    width: 4.2vw;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .feedback {
        gap: 14.58vw;

        .feedback-header-container {
            .feedback-header-buttons {
                gap: 3vw;

                .feedback-header-button {
                    width: 9.375vw;
                    height: 9.375vw;

                    svg {
                        width: 2.1vw;
                    }
                }
            }
        }

        .feedback-list-container {
            .feedback-list-item {

                .feedback-list-item-header {
                    gap: 6vw;

                    .feedback-list-item-header-content {
                        gap: 6vw;

                        p {
                            font-size: 4.14vw;
                        }

                        &::before {
                            width: 1.2vw;
                            height: 15vw;
                        }
                    }
                }
            }

            .feedback-list-item-footer {
                margin-top: 10.5vw;

                .feedback-list-item-footer-content {
                    gap: 5.1vw;

                    img {
                        width: 12.9vw;
                        height: 12.9vw;
                    }

                    .feedback-list-item-footer-name {
                        h2 {
                            font-size: 3.9vw;
                        }

                        p {
                            font-size: 2.7vw;
                        }
                    }
                }

                .feedback-list-item-footer-quote {
                    width: 8.4vw;
                }
            }
        }
    }
}
