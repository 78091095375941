.login{
    padding: 15.83vw 0 10vw;
    
    .login-container{
        display: flex;
        flex-direction: column;
        gap: 3.33vw;

        .login-inputs {
            display: flex;
            flex-direction: column;
            gap: 1.7vw;
        }
    }

}


@media (max-width: 768px) {
    .login {
        padding: 23.75vw 0 15vw;

        .login-container {
            gap: 5vw;
            
            .login-inputs {
                gap: 2.55vw;
            }
        }
    }
}

@media (max-width: 430px) {
    .login {
        padding: 47.49vw 0 30vw;

        .login-container {
            gap: 10vw;

            .login-inputs {
                gap: 5.1vw;
            }
        }

    }
}