.home-services {
    display: flex;
    flex-direction: column;

    .home-services-title-container {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .home-services-list-wrapper {
        margin-top: 4.5vw;
        background: url('../../../assets//home/services.png');
        background-size: cover;
        background-position: center;

        .home-services-list {
            display: flex;
            flex-direction: column;

            .home-services-list-item {
                display: flex;
                justify-content: space-between;
                padding: 2.22vw 0;
                align-items: center;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                cursor: pointer;

                &:hover {
                    svg {
                        transform: rotate(-45deg);
                    }
                }

                svg {
                    width: 2.56vw;
                    transition: all ease 0.4s;
                }

                .home-services-list-item-content {
                    display: flex;
                    align-items: start;
                    gap: 0.9vw;

                    p {
                        font-size: 1.11vw;
                        color: #aaa;
                    }

                    h2 {
                        color: #fff;
                        font-size: 2.7vw;
                        font-family: 'Ganora';
                        margin-top: 0.6vw;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .home-services {
        .home-services-list-wrapper {
            .home-services-list {
                .home-services-list-item {
                    padding: 4.5vw 0;
                    gap: 3vw;

                    svg {
                        width: 5.1vw;
                    }

                    .home-services-list-item-content {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 1.5vw;

                        p {
                            font-size: 2vw;
                        }

                        h2 {
                            font-size: 5.25vw;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .home-services {
        .home-services-list-wrapper {
            .home-services-list {
                .home-services-list-item {
                    padding: 6vw 0;
                    gap: 4vw;

                    svg {
                        width: 8vw;
                    }

                    .home-services-list-item-content {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 2vw;

                        p {
                            font-size: 3.5vw;
                        }

                        h2 {
                            font-size: 8vw;
                        }
                    }
                }
            }
        }
    }
}
