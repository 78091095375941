.input-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1vw;
    width: 100%;
    
    label{
        font-weight: 500;
        font-size: 1.25vw;
    }

    .input-error{
        color: #ed4337;
        font-size: 1.11vw; 
    }


    .input {
        width: 100%;
        padding: 1.18vw 1.5vw;
        border: 1px solid #fff;

        input, textarea {
            outline: none;
            font-size: 1.11vw;
            background: none;
            border: none;
            color: #fff;
            width: 100%;
        }

        textarea{
            resize: none;
            width: 100%;
            height: 10vw;
        }
    }

}


@media (max-width: 768px) {
    .input-wrapper{
        gap: 1.5vw;

        label{
            font-size: 1.85vw;
        }

        .input-error{
            font-size: 1.665vw; 
        }

        .input {
            padding: 1.77vw 2.25vw;


            input, textarea {
                font-size: 1.665vw;
            }

            textarea{
                height: 15vw;
            }
        }
    }
}

@media (max-width: 430px) {
    .input-wrapper{
        gap: 3vw;

        label{
            font-size: 3.7vw;
        }


        .input-error{
            font-size: 3.33vw; 
        }

        .input {
            padding: 3.54vw 4.5vw;


            input, textarea {
                font-size: 3.33vw;
            }

            textarea{
                height: 30vw;
            }
        }
    }
}
