.contact-us {
    width: 48vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .contact-us-link {
        display: flex;
        gap: 1.3vw;
        text-decoration: none;

        .title {
            color: #9C7339;
            cursor: pointer;
        }

        svg {
            width: 2.5vw;
            transition: all 0.4s ease;

            path {
                stroke: #9C7339;
            }
        }

        &:hover {
            svg {
                transform: rotate(-45deg);
            }
        }
    }
}

@media (max-width: 768px) {
    .contact-us {
        width: 72vw;

        .contact-us-link {
            gap: 1.95vw;

            svg {
                width: 3.75vw;
            }
        }
    }
}

@media (max-width: 430px) {
    .contact-us {
        width: 95vw;

        .contact-us-link {
            gap: 3.9vw;

            svg {
                width: 7.5vw;
            }
        }
    }
}
