.home-portfolio {
    display: flex;
    flex-direction: column;
    gap: 4.86vw;

    .home-portfolio-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .home-portfolio-title-container {
            .subtitle,
            .title {
                text-align: left;
            }
        }
    }

    .home-portfolio-list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 2vw;

        .home-portfolio-list-item {
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 1vw;
            cursor: pointer;

            img {
                border: 1px solid #aaa;
                width: 100%;
            }

            .home-portfolio-list-item-title {
                color: #fff;
                font-size: 2.7vw;
                font-family: "Ganora";
                margin-top: 0.6vw;
                font-weight: 400;
                text-align: center;
            }

            .home-portfolio-list-item-description {
                font-size: 0.97vw;
                font-weight: 600;
                text-align: left;
                color: #888;
            }
        }
    }
}

@media (max-width: 768px) {
    .home-portfolio {
        gap: 7.5vw;

        .home-portfolio-list {
            flex-direction: column;
            gap: 5vw;

            .home-portfolio-list-item {
                width: 90%;
                gap: 2vw;

                .home-portfolio-list-item-title {
                    font-size: 5.25vw;
                }

                .home-portfolio-list-item-description {
                    font-size: 1.5vw;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .home-portfolio {
        gap: 12vw;

        .home-portfolio-list {
            flex-direction: column;
            gap: 10vw;

            .home-portfolio-list-item {
                width: 100%;
                gap: 4vw;

                .home-portfolio-list-item-title {
                    font-size: 9vw;
                }

                .home-portfolio-list-item-description {
                    font-size: 3vw;
                }
            }
        }
    }
}
