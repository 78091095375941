.notification{
    padding: 2vw 1vw;
    border-radius: 1vw;
    background: rgba(216, 240, 211);
    width: 35vw;
    left: calc(50% - 17.5vw);
    position: fixed;
    z-index: 3;
    top: -20vw; /* Initially hidden above view */
    opacity: 0; /* Initially invisible */
    transition: top 1s ease, opacity 1s ease;

    &.error{
        background: rgba(240, 211, 211, 0.9); 

        p{
            color: rgb(153, 42, 42); 
        }
    }

    &.open {
        top: 10vw;
        opacity: 1;
    }

    &.close {
        top: -20vw;
        opacity: 0;
    }

    p{
        color: rgb(91, 113, 83);
        font-size: 1.11vw;
        text-align: center;
    }
}
