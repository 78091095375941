.admin-card{
    background: #333;
    padding: 1vw;
    border-radius: 0.5vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;

    .admin-card-data-item{
        display: flex;
        flex-direction: column;
        gap: 1vw;

        .admin-card-data-text{
            font-size: 1.11vw;
        }
    }

    .admin-pagination-button{
        background: #181818;
        margin: 0 auto;
    }
}