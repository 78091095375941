.about-us-banner{
    padding: 2.78vw 5.5vw;
    background: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .about-us-banner-title-container{
        display: flex;
        flex-direction: column;
        width: 27.15vw;

        .title{
            text-align: left;
            font-size: 2.8vw;
        }

        .subtitle{
            text-align: left;
            text-transform: none;
            font-weight: 400;
        }
    }

    .about-us-banner-content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 8.3vw;
        grid-row-gap: 0.97vw;

        .title{
            text-align: left;
            font-size: 2.8vw;
        }

        .subtitle{
            text-align: left;
            text-transform: none;
            font-weight: 400;
        }
    }
}

@media (max-width: 768px) {
    .about-us-banner {
        padding: 4.17vw 8.25vw;
        flex-direction: column;
        gap: 6.45vw;

        .about-us-banner-title-container {
            width: 100%;

            .title {
                font-size: 4.2vw;
            }
        }

        .about-us-banner-content {
            width: 100%;
            grid-column-gap: 12.45vw;
            grid-row-gap: 1.455vw;

            .title {
                font-size: 4.2vw;
            }
        }
    }
}

/* Media query for mobiles (430px) */
@media (max-width: 430px) {
    .about-us-banner {
        padding: 8.34vw 8.5vw;
        gap: 12.9vw;

        .about-us-banner-title-container {
            width: 100%;

            .title {
                font-size: 8.4vw;
            }
        }

        .about-us-banner-content {
            grid-column-gap: 24.9vw;
            grid-row-gap: 2.91vw;

            .title {
                font-size: 8.4vw;
            }
        }
    }
}