.admin{
    padding: 15.83vw 0 10vw;
    display: flex;
    flex-direction: column;
    gap: 2vw;

    .admin-cards-container{    
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 3vw;
    }

    .admin-pagination-container{
        max-width: 80%;
        margin: 0 auto;
        display: flex;
        gap: 1vw;

        .admin-pagination-button:first-child{
            margin-right: 2vw;
            svg{
                transform: rotate(-180deg);
            }
        }

        .admin-pagination-button:last-child{
            margin-left: 2vw;
        }
    }

}
