.about-us{

    background: #181818;
    width: 100%;

    .about-us-hero {
        padding: 14.23vw 0 8.68vw;
        display: flex;
        justify-content: center;
    
        h2 {
          font-size: 9.72vw;
          font-weight: 400;
          font-family: 'Ganora';
        }
    }

    .about-us-content{
        display: flex;
        flex-direction: column;
        gap: 8.3vw;
        padding-bottom: 8vw;
    }
}



@media (max-width: 768px) {
    .about-us {
        .about-us-hero{
            padding: 21.34vw 0 13.02vw;

                h2 {
                    font-size: 14.58vw;
                }
            }

            .about-us-content {
            gap: 16.67vw;
            padding-bottom: 12vw;
        }
    }
}

  @media (max-width: 430px) {
    .about-us {
        .about-us-hero {
            padding: 42.69vw 0 26.04vw;

            h2 {
                font-size: 18.16vw;
                text-align: center;
            }
        }

        .about-us-content {
            gap: 33.33vw;
            padding-bottom: 24vw;
        }
    }
}