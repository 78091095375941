.home-content {
    padding: 13.89vw 0;
    background: #181818;
    display: flex;
    flex-direction: column;
    gap: 13.89vw;

    @media (max-width: 768px) {
        padding: 10vw 0;
        gap: 10vw;
    }

    @media (max-width: 430px) {
        padding: 8vw 0;
        gap: 8vw;
    }
}
