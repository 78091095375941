.admin-pagination-button{
    background: #333;
    padding: 0.5vw;
    border-radius: 0.5vw;
    width: 3vw;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.4s;

    &:hover{
        transform: scale(1.1);
    }

    p{
        font-size: 0.97vw;
    }

    svg{
        fill: #fff;
        width: 2vw;
    }

    &.active{
        border: 1px solid #fff;
    }
}